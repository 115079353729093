<template>
    <base-content>
        <template #button> </template>
        <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 18 }">
            <a-form-item label="物料详情">
                <a-button @click="material_list.push({ material_id: '', material_code: '', batch_number: '', name: '', count: '', unit: '' })">新增空行</a-button>
                <a-button @click="$refs.SelectValidMaterial.start()" style="margin-left:12px">从物料库中添加</a-button>
                <a-table style="margin-top:6px" :columns="table_columns" bordered size="small" :data-source="material_list" rowKey="material_id" :pagination="false">
                    <template #material_code="scoped">
                        <a-input size="small" v-model:value="scoped.record.material_code" :maxlength="7" @change="inputMaterialCode" :index="scoped.index"></a-input>
                    </template>
                    <template #batch_number="scoped">
                        <a-select size="small" v-model:value="scoped.record.batch_number" :options="scoped.record.batch_number_list" allowClear @change="changeBatchNumber(scoped.index)" />
                    </template>
                    <template #supplier="scoped">
                        <a-select size="small" v-model:value="scoped.record.supplier_id" :options="scoped.record.supplier_list" allowClear @change="changeSupplier(scoped.index)" />
                    </template>
                    <template #operation="scoped">
                        <a-button type="link" size="small" @click="material_list.splice(scoped.index, 1)">删除</a-button>
                    </template>
                    <template #count="scoped">
                        <a-input size="small" style="text-align:right" v-model:value="scoped.record.count"></a-input>
                    </template>
                </a-table>
            </a-form-item>
            <a-form-item label="操作备注">
                <a-input v-model:value="remark"></a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 14, offset: 2 }">
                <a-button type="primary" @click="submit">提交</a-button>
            </a-form-item>
        </a-form>
        <a-modal v-model:visible="confirm_modal_visible" title="出库申请确认" :width="888" @ok="confrim">
            <a-table :columns="confirm_table_columns" :data-source="confirm_material_list" bordered size="small" rowKey="index" :pagination="false">
                <template #title>出库项</template>
            </a-table>
        </a-modal>
        <SelectValidMaterial ref="SelectValidMaterial" dataType="code" :multiple="true" @finish="finish" />
    </base-content>
</template>

<script>
import DataLog from '../components/modal/DataLog';
import SelectValidMaterial from '../components/modal/SelectValidMaterial';
export default {
    components: {
        DataLog,
        SelectValidMaterial,
    },
    data() {
        return {
            permissions: [],
            table_columns: [
                { title: '编码', slots: { customRender: 'material_code' }, width: 85 },
                { title: '名称', dataIndex: 'name' },
                { title: '批号', slots: { customRender: 'batch_number' }, width: 160 },
                { title: '供应商', slots: { customRender: 'supplier' }, width: 130 },
                { title: '储存区', dataIndex: 'storage_name', width: 100 },
                { title: '数量', slots: { customRender: 'count' }, width: 60, align: 'right' },
                { title: '库存', dataIndex: 'inventory', width: 60, align: 'right' },
                { title: '单位', dataIndex: 'unit', width: 50, align: 'center' },
                { title: '操作', align: 'center', width: 60, slots: { customRender: 'operation' } },
            ],
            storage_tree: [],
            supplier_list: [],
            storage_list: [],
            material_list: [],
            remark: '',
            confirm_modal_visible: false,
            confirm_material_list: [],
            confirm_table_columns: [
                { title: '编码', dataIndex: 'material_code', width: 85 },
                { title: '名称', dataIndex: 'name' },
                { title: '批号', dataIndex: 'batch_number', width: 150 },
                { title: '供应商', dataIndex: 'supplier_name', width: 120 },
                { title: '储存区', dataIndex: 'storage_name', width: 100 },
                { title: '数量', dataIndex: 'count', width: 60, align: 'right' },
                { title: '库存', dataIndex: 'inventory', width: 60, align: 'right' },
            ],
        };
    },
    watch: {},
    mounted() {
        this.$api('getStorageTree').then((res) => {
            this.storage_tree = res.storage_tree;
        });
        this.$api('getTotalSupplierList').then((res) => {
            this.supplier_list = res.supplier_list;
        });
        this.$api('getStorageList', { parent_id: -1 }).then((res) => {
            this.storage_list = res.storage_list;
        });
    },
    methods: {
        _10to32(num10) {
            num10 = parseInt(num10);
            if (num10 < 0 || num10 > 1048575) {
                throw new Error('用于转换的10进制数太小或太大，仅限0~1048575，而你传入了：' + num10);
            }
            let map = '0123456789ABCEFGHJKLMNPQRSTUVWXY';
            let str32 = '';
            while (true) {
                str32 = map[num10 % 32] + str32;
                num10 = parseInt(num10 / 32);
                if (num10 == 0) {
                    return ('000' + str32).slice(-4);
                }
            }
        },
        _32to10(str32) {
            str32 = String(str32);
            if (str32.length > 4 || str32.length < 1) {
                throw new Error('用于转换的32进制数太小或太大，仅限0000~YYYY，而你传入了：' + str32);
            }
            let num10 = 0;
            let map = '0123456789ABCEFGHJKLMNPQRSTUVWXY';
            for (let i = 0; i < str32.length; i++) {
                let index = map.indexOf(str32[i]);
                if (index == -1) {
                    throw new Error('用于转换的32进制数字符不符，仅限0123456789ABCEFGHJKLMNPQRSTUVWXY，而你传入了：' + str32[i]);
                }
                num10 += index * 32 ** (str32.length - 1 - i);
            }
            return num10;
        },
        async inputMaterialCode(event) {
            let material_code = event.target.value;
            if (material_code.length == 7) {
                material_code = material_code.toUpperCase();
                material_code = material_code.split('-');
                if (material_code.length != 2 || material_code[0].length != 2 || material_code[1].length != 4) {
                    this.$message.error('物料编码格式不正确，应为XX-XXXX');
                    return;
                }
                let [prefix, suffix] = [material_code[0], material_code[1]];
                let f = (v) => {
                    return '0123456789ABCEFGHJKLMNPQRSTUVWXY'.indexOf(v) != -1;
                };
                if (!prefix.split('').every(f) || !suffix.split('').every(f)) {
                    this.$message.error('物料编码格式不正确，使用了不合法的字符');
                    return;
                }
                suffix = this._32to10(suffix);
                let index = event.target.getAttribute('index');
                let res = await this.$api('getMaterialBrief', { prefix, suffix });
                this.material_list[index].name = res.result ? res.material_brief.name : '';
                this.material_list[index].unit = res.result ? res.material_brief.unit : '';
                this.material_list[index].material_id = res.result ? res.material_brief.material_id : '';
                this.material_list[index].material_code = res.result ? material_code.join('-') : '';
                if (!res.result) {
                    this.$message.error('不存在该物料，请核对后再试');
                    return;
                }
                res = await this.$api('getMaterialInventoryList', { material_id: res.material_brief.material_id });
                if (res.inventory_list.length == 0) {
                    this.$message.error('该物料不存在有库存的批次可供选择');
                    return;
                }
                let supplier_list = [];
                let batch_number_list = [];
                res.inventory_list.forEach((row) => {
                    let supplier_flag = true;
                    supplier_list.forEach((item) => {
                        if (item.value == row.supplier_id) supplier_flag = false;
                    });
                    if (supplier_flag)
                        this.supplier_list.forEach((item) => {
                            if (item.supplier_id == row.supplier_id) supplier_list.push({ value: item.supplier_id, label: item.name });
                        });
                    let batch_number_flag = true;
                    batch_number_list.forEach((item) => {
                        if (item.value == row.batch_number) batch_number_flag = false;
                    });
                    if (batch_number_flag) batch_number_list.push({ value: row.batch_number, label: row.batch_number });
                });
                this.material_list[index].inventory_list = res.inventory_list;
                this.material_list[index].supplier_list = supplier_list;
                this.material_list[index].batch_number_list = batch_number_list;
            }
        },
        /**
         * 接收从物料库中返回的结果
         */
        finish(code_list) {
            code_list.forEach(async (item) => {
                item = item.split('-');
                let res1 = await this.$api('getMaterialBrief', { prefix: item[0], suffix: this._32to10(item[1]) });
                let res2 = await this.$api('getMaterialInventoryList', { material_id: res1.material_brief.material_id });
                let supplier_list = [];
                let batch_number_list = [];
                res2.inventory_list.forEach((row) => {
                    let supplier_flag = true;
                    supplier_list.forEach((item) => {
                        if (item.value == row.supplier_id) supplier_flag = false;
                    });
                    if (supplier_flag)
                        this.supplier_list.forEach((item) => {
                            if (item.supplier_id == row.supplier_id) supplier_list.push({ value: item.supplier_id, label: item.name });
                        });
                    let batch_number_flag = true;
                    batch_number_list.forEach((item) => {
                        if (item.value == row.batch_number) batch_number_flag = false;
                    });
                    if (batch_number_flag) batch_number_list.push({ value: row.batch_number, label: row.batch_number });
                });
                let material = {
                    material_id: res1.material_brief.material_id,
                    material_code: item.join('-'),
                    name: res1.material_brief.name,
                    supplier_list,
                    supplier_id: '',
                    batch_number_list,
                    batch_number: '',
                    storage_name: '',
                    count: '',
                    inventory: '',
                    inventory_list: res2.inventory_list,
                    unit: res1.material_brief.unit,
                };
                this.material_list.push(material);
            });
        },
        changeSupplier(index) {
            let inventory_list = this.material_list[index].inventory_list;
            // 本次选中的供应商id值
            let supplier_id = this.material_list[index].supplier_id;
            // 可供选择的批号列表
            let could_select_batch_number_list = [];
            inventory_list.forEach((item) => {
                if (supplier_id == undefined || supplier_id == item.supplier_id) could_select_batch_number_list.push(item.batch_number);
            });
            // 将所有不可选择的选项置为禁用
            this.material_list[index].batch_number_list.forEach((item) => {
                item.disabled = could_select_batch_number_list.indexOf(item.value) == -1;
            });
            this.refreshStorageAndCount(index);
        },
        changeBatchNumber(index) {
            // 原理同 changeSupplier
            let inventory_list = this.material_list[index].inventory_list;
            let batch_number = this.material_list[index].batch_number;
            let could_select_supplier_id_list = [];
            inventory_list.forEach((item) => {
                if (batch_number == undefined || batch_number == item.batch_number) could_select_supplier_id_list.push(item.supplier_id);
            });
            this.material_list[index].supplier_list.forEach((item) => {
                item.disabled = could_select_supplier_id_list.indexOf(item.value) == -1;
            });
            this.refreshStorageAndCount(index);
        },
        refreshStorageAndCount(index) {
            let inventory_list = this.material_list[index].inventory_list;
            let batch_number = this.material_list[index].batch_number;
            let supplier_id = this.material_list[index].supplier_id;
            if (!batch_number || !supplier_id) [this.material_list[index].inventory, this.material_list[index].storage_name] = ['', ''];
            inventory_list.forEach((item) => {
                if (batch_number == item.batch_number && supplier_id == item.supplier_id) {
                    this.material_list[index].inventory = item.count;
                    this.storage_list.forEach((storage) => {
                        if (item.storage_id == storage.id) this.material_list[index].storage_name = storage.name;
                    });
                }
            });
        },
        submit() {
            let flag = false;
            let valid_material_list = [];
            for (let i = 0; i < this.material_list.length; i++) {
                if (!flag && this.material_list[i].material_code && !this.material_list[i].material_id) flag = `列表的第${i + 1}行不是有效物料`;
                if (!flag && !this.material_list[i].batch_number) flag = `列表的第${i + 1}行未选择批号`;
                if (!flag && !this.material_list[i].supplier_id) flag = `列表的第${i + 1}行未选择供应商`;
                if (!flag && this.material_list[i].count === '') flag = `列表的第${i + 1}行未输入出库数量`;
                if (!flag && isNaN(Number(this.material_list[i].count))) flag = `列表的第${i + 1}行出库数量输入有误`;
                if (!flag && Number(this.material_list[i].count) <= 0) flag = `列表的第${i + 1}行出库数量必须大于0`;
                if (!flag && Number(this.material_list[i].count) > this.material_list[i].inventory) flag = `列表的第${i + 1}行出库数量不能大于当前库存量`;
                if (!flag) {
                    this.supplier_list.forEach((item) => {
                        if (item.supplier_id == this.material_list[i].supplier_id) this.material_list[i].supplier_name = item.name;
                    });
                    this.material_list[i].count = Number(this.material_list[i].count);
                    this.material_list[i].index = i;
                    valid_material_list.push(this.material_list[i]);
                }
                if (flag) break;
            }
            if (!flag && valid_material_list.length == 0) flag = '请至少添加一项物料';
            let material_key_list = [];
            if (!flag)
                for (let i = 0; i < valid_material_list.length; i++) {
                    let material_key = `${valid_material_list[i].material_id}_${valid_material_list[i].supplier_id}_${valid_material_list[i].batch_number}`;
                    if (material_key_list.indexOf(material_key) != -1) flag = `列表中第${material_key_list.indexOf(material_key) + 1}行与第${i + 1}行重复`;
                    if (!flag) material_key_list.push(material_key);
                    if (flag) break;
                }
            if (flag) this.$message.error(flag);
            if (flag) return;

            this.confirm_material_list = valid_material_list;
            this.confirm_modal_visible = true;
        },
        confrim() {
            this.confirm_modal_visible = false;
            this.$api('applyOutStorage', { material_list: JSON.stringify(this.confirm_material_list), remark: this.remark }).then((res) => {
                if (res.result) {
                    this.material_list = [];
                    this.remark = '';
                    this.$modal.success({
                        title: '提交完成',
                        content: '出库申请已提交，审核通过后才会变更库存数量，本次出库流水号：' + res.serial + '。',
                    });
                }
            });
        },
    },
};
</script>

<style></style>
